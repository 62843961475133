.cat-values {
  margin-bottom: 5rem;
  .line-shape {
    display: flex;
    img {
      max-height: 220px;
    }
  }
  &__container {
    margin-top: -3rem;
  }
  .service-wrap {
    row-gap: 3rem;
    margin-top: 2.8rem;
  }
  &__card {
    box-shadow: 0px 4px 14px rgba(250, 234, 56, 0.29);
    border-radius: 10px;
    background-color: #ffffff;
    display: block;
    border: 2px solid #fff58a73;
    padding: 16px 24px;
    margin-bottom: 1.5rem;
    max-height: max-content;
    max-width: 95%;
    width: 100%;
    position: relative;
    @include mq(sm) {
      max-width: 100%;
    }
    @include mq(xs) {
      max-width: 100%;
    }
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    &-name {
      font-size: 1.4rem;
      font-weight: 500;
      padding-top: 2.5rem;
    }
    &-icon{
        position: absolute;
        top: -36px;
    }
    cursor: pointer;
    p {
      transition: max-height 1s;
      max-height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 1rem;
      line-height: 2rem;
    }
    transition: max-height 2s;
    &:hover {
      box-shadow: 0px 4px 14px rgba(250, 234, 56, 0.6);

      // animation-iteration-count: 1;
      p {
        max-height: 15rem;
        display: flex;
      }
      .cat-services__card-icon {
        animation-name: wobble-vertical2;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 3;
      }
    }
  }
  .cat-exp {
    background-color: #f1f1f1;
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin-top: 5rem;
    background-image: url("../../img/bg/cta-11.webp");
    background-size: cover;
    background-repeat: no-repeat;
    h3 {
      font-size: 3rem;
    }
    .cat-services__card {
      box-shadow: 0px 4px 14px rgba(32, 27, 89, 0.12);
      border-radius: 10px;
      background-color: #ffffff;
      display: block;
      border: 2px solid transparent;
      padding: 16px 24px;
      margin-bottom: 1.5rem;
      position: relative;
      z-index: 1;
      &:hover {
        box-shadow: 0px 4px 14px rgba(32, 27, 89, 0.3);
        .cat-services__card-icon {
          animation-name: hvr-buzz-out;
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
        }
      }
    }
  }
}
@keyframes slideIn {
  from {
    height: 0px;

    // height: auto;
  }

  to {
    height: max-content;

    // height: 100%;
  }
}
@keyframes slideOut {
  from {
    height: max-content;
    opacity: 1;
    // height: auto;
  }

  to {
    height: 0;
    opacity: 0;
    // height: 100%;
  }
}
@keyframes slideInCard {
  from {
    height: auto;
    visibility: hidden;
  }

  to {
    height: 100%;
    visibility: visible;
  }
}
