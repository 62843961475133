.cat {
  &-inner-header {
    background-image: url("../../img/bg/breadcrumb_bg.webp");
    background-size: cover;
    min-height: 350px;
    .inner-wrap{
        min-height: 350px;
    }
    &__title {
        padding-top: 5rem;
        font-size: 3.5rem;
        font-weight: bold;
        color: #ffffff;
        &::after {
            content: " ";
            border-bottom: 3px solid #fff;
            padding-top: 6px;
            width: 100%;
            display: flex;
          }
    }
  }
}
