.cat-blog{
    margin-top: 5rem;
    &__no-post{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
        border: 1px solid #000c5f;
        flex-flow: column;
        h3{
            font-size: 3rem;
            font-weight: 500;
        }
        p{
            font-size: 1.2rem;
        }
    }
}