.cat-team {
  margin-bottom: 5rem;
  position: relative;
  .ceo-card {
    background-color: #e8efff;
    padding-top: 3rem;
    padding-bottom: 2rem;
    padding-right: 2rem;
    border-radius: 1rem;
    .cat-section__content {
      font-size: 1.2rem;
      font-style: italic;
    }
  }
  .profile-pic {
    background-image: url("../../img/bg/profile.webp");
    max-width: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 350px;
    min-height: 350px;
    box-shadow: 0px 4px 14px rgba(32, 27, 89, 0.12);
    border-radius: 16rem;
    border: 8px solid #ffffff;
    animation-delay: 0.2s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
    transform: scale(1);
    position: relative;
    cursor: pointer;
    .linkdn-icon {
      position: absolute;
      visibility: hidden;
    }
    &:hover {
      .linkdn-icon {
        visibility: visible;
        animation-name: wobble-vertical;
        animation-timing-function: ease-in-out;
        animation-delay: 0.2s;
        align-items: center;
        display: flex;
        left: 9rem;
        bottom: 3rem;
        img {
          animation-name: hvr-buzz-out;
        }
      }
      animation-delay: 0.2s;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      transition: all 0.5s;
      transform: scale(1.05);

      box-shadow: inset 1rem 1rem 1rem 20rem #002129a1;
    }
  }
  .line-shape {
    position: absolute;
    left: -23.7%;
    top: -80px;
    z-index: -2;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
