.cat-about {
  .about-img{
    max-width: 500px;
  }
  scroll-margin-top: 6rem;
  margin-top: 5rem;
  &__vission{
    margin-top: 5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-image: url("../../img/bg/vissionbg.webp");

    /* Set a specific height */
    min-height: 500px; 
  
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .cat-section {
        
        &__title{
            .main-title {
                h2 {
                  font-size: 3rem;
                  font-weight: bold;
                  color: hsl(0, 0%, 90%);
                }
              }
            }
            &__sub-titile {
              display: flex;
              justify-content: center;
                p {
                  font-size: 1.2rem;
                  width: 80%;
                  font-weight: 400;
                  color: hsl(0, 0%, 90%);
                  text-align: center;
                }
              }
        }
  }
}
