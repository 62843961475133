.cat-career{
    margin-top: 5rem;
    &__vacancy{
        &-header{
            line-height: 2rem;
            margin-top: 3rem;
            p{
                font-size: 1.2rem;
                margin: 0px;
                font-weight: 500;
                color: #003fc6;
            }
            h3{
                font-size: 2rem;
                font-weight: 700;
                font-family: inherit;
                color: #5b5b5c;
            }
        }
        &-content{
            margin-top: 3rem;
            line-height: 2.5rem;
            .accordion-header{
                button{
                    font-size: 1.1rem;
                }
            }
            h3{
                font-family: inherit;
                margin: 0;
            }
            ul {
                padding-left: 20px;
              }
              
        
              .position-overview, .key-responsibilities, .requirements, .benefits, .how-to-apply {
                margin-bottom: 30px;
              }
              
        }
    }
}