.cat-training {
  margin-top: 5rem;
  .cat-section__sub-titile {
    max-width: 80%;
    padding-top: 1rem;
    line-height: 2.5rem;
  }
  &__content {
    margin-top: 2.5rem;
    line-height: 2.5rem;
    border: 1px solid #ddd;
    border-radius: 1rem;
    padding: 3rem;

    h2 {
      font-family: inherit;
      margin: 0;
    }
    ul {
      padding-left: 20px;
    }

    .training-benefits,
    .training-topics,
    .certifications,
    .how-to-register {
      margin-bottom: 30px;
    }
  }
}
