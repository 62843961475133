.cat-header {
  &.header-sticky {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99999;
    @include transition(0.3s);
    .navbar-nav {
      gap: 1rem;
      font-size: 1rem;

      a {
        color: #fff;
        font-family: $play;
        text-decoration: none;
        justify-content: center;
        align-items: center;
        display: flex;
        &:hover {
          color: #10ffe2;
        }
      }
    }
    @media (max-width: 991px) {
      background-color: #fff;
      -webkit-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
      -moz-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.078);
      box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
      .navbar-nav {
        text-align: center;
        a {
          color: #222;
          &.btn {
            color: #ffffff;
          }
          &:hover {
            color: #006eff;
          }
        }
      }
      .logo-1 {
        img {
          max-width: 62px;
        }
      }
      .navbar-collapse {
        background-color: #f1f9ff;
        border-radius: 1rem;
        padding: 1rem;
      }
    }
  }
  &.sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    background-color: #fff;
    animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
    display: block;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
    -moz-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.078);
    box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
    .navbar-nav {
      a {
        color: #222;
        &.btn {
          color: #ffffff;
        }
        &:hover {
          color: #006eff;
        }
      }
    }
  }
  .cat-logo {
    & .logo-2 {
      display: none;
    }
    & .logo-1 {
      text-decoration: none;
      font-family: $play;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 2px;
      color: #ffffff;
      span {
        padding-left: 16px;
        @include mq(sm) {
          display: none;
        }
        @include mq(xs) {
          display: none;
        }
      }
    }
  }
}
