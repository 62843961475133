/*
--------------------------------------------
4.animated
*/
@use '../abstracts/mixins' as *;

/* circle animation effect 
----------------------------------*/
@keyframes hero-thumb-animation {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes hero-thumb-sm-animation {
  0% {
    -webkit-transform: translateY(-20px) translateX(50px);
    -moz-transform: translateY(-20px) translateX(50px);
    -ms-transform: translateY(-20px) translateX(50px);
    transform: translateY(-20px) translateX(50px);
  }
  100% {
    -webkit-transform: translateY(-20px) translateX(0px);
    -moz-transform: translateY(-20px) translateX(0px);
    -ms-transform: translateY(-20px) translateX(0px);
    transform: translateY(-20px) translateX(0px);
  }
}
@keyframes hero-thumb-sm-2-animation {
  0% {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }
  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes services-triangle {
  0% {
    -webkit-transform: rotate(0deg) translateX(-50px);
    -moz-transform: rotate(0deg) translateX(-50px);
    -ms-transform: rotate(0deg) translateX(-50px);
    transform: rotate(0deg) translateX(-50px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(100px);
    -moz-transform: rotate(360deg) translateY(100px);
    -ms-transform: rotate(360deg) translateY(100px);
    transform: rotate(360deg) translateY(100px);
  }
}
.circle-shape-change {
    -webkit-border-radius: 45% 55% 60% 40% / 59% 56% 44% 41%;
  -moz-border-radius: 45% 55% 60% 40% / 59% 56% 44% 41%;
  border-radius: 45% 55% 60% 40% / 59% 56% 44% 41%;
  -webkit-animation: hero-border 2s linear infinite alternate;
  -moz-animation: hero-border 2s linear infinite alternate;
  -o-animation: hero-border 2s linear infinite alternate;
  animation: hero-border 2s linear infinite alternate;
}

@keyframes hero-border {
    0% {
        -webkit-border-radius: 45% 55% 60% 40% / 59% 56% 44% 41%;
        -moz-border-radius: 45% 55% 60% 40% / 59% 56% 44% 41%;
        border-radius: 45% 55% 60% 40% / 59% 56% 44% 41%;
    }
    100% {
        -webkit-border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
        -moz-border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
        border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
    }
}




/* movement animation effect 
----------------------------------*/

.horizontal_move50px {
  -webkit-animation: horizontal_move50 8s linear 0s infinite alternate;
  -moz-animation: horizontal_move50 8s linear 0s infinite alternate;
  -o-animation: horizontal_move50 8s linear 0s infinite alternate;
  animation: horizontal_move50 8s linear 0s infinite alternate;
}
@keyframes horizontal_move50 {
    0% {
      -webkit-transform: translateX(50px);
      -moz-transform: translateX(50px);
      -ms-transform: translateX(50px);
      transform: translateX(50px);
    }
    100% {
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }
  }

.horizontal_move100px {
  -webkit-animation: horizontal_move100 5s linear 0s infinite alternate;
  -moz-animation: horizontal_move100 5s linear 0s infinite alternate;
  -o-animation: horizontal_move100 5s linear 0s infinite alternate;
  animation: horizontal_move100 5s linear 0s infinite alternate;
}
@keyframes horizontal_move100 {
    0% {
      -webkit-transform: translateX(-100px);
      -moz-transform: translateX(-100px);
      -ms-transform: translateX(-100px);
      transform: translateX(-100px);
    }
    100% {
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }
  }
  .upward_movement200px {
    -webkit-animation: upward_movement200 8s linear 0s infinite alternate;
    -moz-animation: upward_movement200 8s linear 0s infinite alternate;
    -o-animation: upward_movement200 8s linear 0s infinite alternate;
    animation: upward_movement200 8s linear 0s infinite alternate;
  }

  @keyframes upward_movement200 {
    0% {
      -webkit-transform: translateY(-200px);
      -moz-transform: translateY(-200px);
      -ms-transform: translateY(-200px);
      transform: translateY(-200px);
    }
    100% {
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }
  }
  @keyframes line_movement {
    0% {
      width: 0%;
    }
  
    100% {
      width: 100%;
    }
  }

/* image animation effect 
----------------------------------*/

.img-animation-left, .img-animation-right {
  -webkit-animation-name: vamtam-scale-out-left;
  animation-name: vamtam-scale-out-left;
  animation-duration: 3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0, 0.9);
  animation-timing-function: cubic-bezier(0.4, 0, 0, 0.9);
  animation-fill-mode: backwards;
}


@keyframes vamtam-scale-out-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}


.img-animation-right {
  -webkit-animation-name: vamtam-scale-out-right;
  -webkit-animation-name: vamtam-scale-out-right;
  animation-name: vamtam-scale-out-right;
  animation-duration: 3s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0, 0.9);
  animation-timing-function: cubic-bezier(0.4, 0, 0, 0.9);
  animation-fill-mode: backwards;
}

@keyframes vamtam-scale-out-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes hero-thumb-sm-animation {
  0% {
    -webkit-transform: translateY(-20px) translateX(50px);
    -moz-transform: translateY(-20px) translateX(50px);
    -ms-transform: translateY(-20px) translateX(50px);
    transform: translateY(-20px) translateX(50px);
  }

  100% {
    -webkit-transform: translateY(-20px) translateX(0px);
    -moz-transform: translateY(-20px) translateX(0px);
    -ms-transform: translateY(-20px) translateX(0px);
    transform: translateY(-20px) translateX(0px);
  }
}

@keyframes hero-thumb-sm-2-animation {
  0% {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px);
  }
}


/* visible animation effect 
----------------------------------*/

.top_right_visible_animation {
  -webkit-animation-name: top_right_visible;
  -webkit-animation-name: top_right_visible;
  animation-name: top_right_visible;
  animation-duration: 5s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0, 0.9);
  animation-timing-function: cubic-bezier(0.4, 0, 0, 0.9);
  animation-fill-mode: backwards;
}

@keyframes top_right_visible {
  from {
    transform: translate(100%, -100%);
  }
  to {
    transform: translate(0, 0);
  }
}


.width_visible_animation {
  -webkit-animation: width_visible 3s linear 0s;
  -moz-animation: width_visible 3s linear 0s;
  -o-animation: width_visible 3s linear 0s;
  animation: width_visible 3s linear 0s;
}
@keyframes width_visible {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}


/* hvr-buzz-out animation effect 
----------------------------------*/

@-webkit-keyframes hvr-buzz-out {
	10% {
		-webkit-transform: translateX(3px) rotate(2deg);
		transform: translateX(3px) rotate(2deg);
	}
	20% {
		-webkit-transform: translateX(-3px) rotate(-2deg);
		transform: translateX(-3px) rotate(-2deg);
	}
	30% {
		-webkit-transform: translateX(3px) rotate(2deg);
		transform: translateX(3px) rotate(2deg);
	}
	40% {
		-webkit-transform: translateX(-3px) rotate(-2deg);
		transform: translateX(-3px) rotate(-2deg);
	}
	50% {
		-webkit-transform: translateX(2px) rotate(1deg);
		transform: translateX(2px) rotate(1deg);
	}
	60% {
		-webkit-transform: translateX(-2px) rotate(-1deg);
		transform: translateX(-2px) rotate(-1deg);
	}
	70% {
		-webkit-transform: translateX(2px) rotate(1deg);
		transform: translateX(2px) rotate(1deg);
	}
	80% {
		-webkit-transform: translateX(-2px) rotate(-1deg);
		transform: translateX(-2px) rotate(-1deg);
	}
	90% {
		-webkit-transform: translateX(1px) rotate(0);
		transform: translateX(1px) rotate(0);
	}
	100% {
		-webkit-transform: translateX(-1px) rotate(0);
		transform: translateX(-1px) rotate(0);
	}
}
@keyframes hvr-buzz-out {
	10% {
		-webkit-transform: translateX(3px) rotate(2deg);
		transform: translateX(3px) rotate(2deg);
	}
	20% {
		-webkit-transform: translateX(-3px) rotate(-2deg);
		transform: translateX(-3px) rotate(-2deg);
	}
	30% {
		-webkit-transform: translateX(3px) rotate(2deg);
		transform: translateX(3px) rotate(2deg);
	}
	40% {
		-webkit-transform: translateX(-3px) rotate(-2deg);
		transform: translateX(-3px) rotate(-2deg);
	}
	50% {
		-webkit-transform: translateX(2px) rotate(1deg);
		transform: translateX(2px) rotate(1deg);
	}
	60% {
		-webkit-transform: translateX(-2px) rotate(-1deg);
		transform: translateX(-2px) rotate(-1deg);
	}
	70% {
		-webkit-transform: translateX(2px) rotate(1deg);
		transform: translateX(2px) rotate(1deg);
	}
	80% {
		-webkit-transform: translateX(-2px) rotate(-1deg);
		transform: translateX(-2px) rotate(-1deg);
	}
	90% {
		-webkit-transform: translateX(1px) rotate(0);
		transform: translateX(1px) rotate(0);
	}
	100% {
		-webkit-transform: translateX(-1px) rotate(0);
		transform: translateX(-1px) rotate(0);
	}
}


/* wobble-vertical animation effect 
----------------------------------*/


@-webkit-keyframes wobble-vertical {
  16.65% {
      transform: translateY(8px);
  }
  33.3% {
      transform: translateY(-6px);
  }
  49.95% {
      transform: translateY(4px);
  }
  66.6% {
      transform: translateY(-2px);
  }
  83.25% {
      transform: translateY(1px);
  }
  100% {
      transform: translateY(0);
  }
}

@-webkit-keyframes animation-pulse-shrink {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.5);
    }
    75% {
        transform: scale(0.7);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes wobble-vertical {
  16.65% {
      transform: translateY(8px);
  }
  33.3% {
      transform: translateY(-6px);
  }
  49.95% {
      transform: translateY(4px);
  }
  66.6% {
      transform: translateY(-2px);
  }
  83.25% {
      transform: translateY(1px);
  }
  100% {
      transform: translateY(0);
  }
}


/* video play animation */

.popup-video:before, .popup-video:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid #cccccc;
  animation: popupBtn 1.8s linear infinite;
}
.popup-video:before {
  animation-delay: 0.8s;
}

@keyframes popupBtn {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.6);
    opacity: 0.3;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes hero-triangle-1 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* icon bounce  */

@keyframes icon-bounce { 
  0%, 100%, 20%, 50%, 80% { transform: translateY(0px); }
  40% { transform: translateY(-10px); }
  60% { transform: translateY(-5px); }
}
@keyframes about-sm {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes hero-thumb-sm-3-animation {
  0% {
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes services-triangle-2 {
  0% {
    -webkit-transform: rotate(0deg) translateX(-15px);
    -moz-transform: rotate(0deg) translateX(-15px);
    -ms-transform: rotate(0deg) translateX(-15px);
    transform: rotate(0deg) translateX(-15px);
  }
  100% {
    -webkit-transform: rotate(360deg) translateY(30px);
    -moz-transform: rotate(360deg) translateY(30px);
    -ms-transform: rotate(360deg) translateY(30px);
    transform: rotate(360deg) translateY(30px);
  }
}
@keyframes services-circle-2 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-15px);
  }
}

@keyframes wobble-vertical2 {
  16.65% {
    transform: translateY(8px);
  }

  33.3% {
    transform: translateY(-6px);
  }

  49.95% {
    transform: translateY(4px);
  }

  66.6% {
    transform: translateY(-2px);
  }

  83.25% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0);
  }
}
.message-box,.map-bg-2 {
  animation-name: message-box;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.map-bg-2 {
  animation-delay: 1s;
}
.body-1-main,.body-2-main,.flower-box-1 {
  animation-name: message-box;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: .9s;
}
.body-2-main {
  animation-delay: .5s;
}
.flower-box-1{
  animation-delay: .11s;
}
@keyframes message-box {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.dot-animated {
  animation-name: dot-animated;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  &.delay-2 {
    animation-delay: .3s;
  }
  &.delay-3 {
    animation-delay: .6s;
  }
}

@keyframes dot-animated {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-4px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
}
.leaves-rotate-1 {
  animation-name: leaves-rotate-1;
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}
.leaves-rotate-2 {
  animation-name: leaves-rotate-2;
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}
@keyframes leaves-rotate-1 {
  0% {
    transform: rotateY(0);
  }
  50% {
    transform: rotateY(35deg);
      transform-origin: left;
  }
  100% {
    transform: rotateY(0);
  }
}
@keyframes leaves-rotate-2 {
  0% {
    transform: rotateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: rotateX(0);
  }
}
.parcentage-box {
  -webkit-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
  -moz-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
  -o-animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
  animation: hero-thumb-sm-3-animation 4s linear infinite alternate;
}

@keyframes hero-thumb-sm-3-animation {
  0% {
    -webkit-transform: translateX(-50px);
    -moz-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
}
.icon-animated-hidden {
  transform: translateX(120px);
  opacity: 0;
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
    transform: translate3d(4px, 0, 0);
  }
}




@keyframes video-scale-up {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.8);
    opacity: 0;
  }
}


@keyframes hero-triangle-2 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes topBottomLine {
  0% {
    top: 0;
  }
  50% {
    top: calc(100% - 30px);
  }
  100% {
    top: 0;
  }
}
@keyframes scaleUpUser {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes roundScaleZoom {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.3);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes hero-bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}
@keyframes hero-circle-2 {
  0% {
    -webkit-transform: translateX(-200px);
    -moz-transform: translateX(-200px);
    -ms-transform: translateX(-200px);
    transform: translateX(-200px);
  }

  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
}


.sasup-hero-content-4,
.slider-content-space-2 {
  & .sasup-hero-subtitle-4,
  & .sasup-hero-title-4,
  & .sasup-hero-text-4,
  & .sasup-hero-started-btn-4 {
      animation-fill-mode: both;
      animation-name: fadeInUp;
  }
}

.sasup-hero-subtitle-4{
  animation-delay: .4s;
  animation-duration: .3s;
}
.sasup-hero-title-4 {
  animation-delay: .5s;
  animation-duration: .5s;
}
.sasup-hero-text-4 {
  animation-delay: .6s;
  animation-duration: .6s;
}
.sasup-hero-started-btn-4 {
  animation-delay: .7s;
  animation-duration: .5s;
}
