@use '../abstracts/mixins' as *;

.cat-slider-area{
    .cat-slider-height-2 {
        min-height: 950px;
        @include mq(xl) {
            min-height: 850px;
        }
        @include mq(lg) {
            min-height: 750px;
        }
        @include mq(md) {
            min-height: 650px;
        }
        @include mq(xs) {
            padding-bottom: 123px;
            padding-top: 32px;
        }
    }
    .cat-slider{
        &-actions {
            @include mq(md) {
                margin-bottom: 10px;
            }
        }
        &-content {
            @include mq(xl) {
                padding-right: 95px;
            }
            @include mq(lg) {
                padding-right: 50px;
            }
            @include mq(md) {
                padding-right: 30px;
            }
            &-subtitle {
                font-size: 14px;
                color: #2f75ff;
                text-transform: uppercase;
                line-height: 1;
                display: block;
                margin-bottom: 13px;
            }
            &-title {
                font-size: 5rem;
                font-family: $play;
                font-weight: 700;
                color: #ffffff;
                line-height: 1.2;
                margin-bottom: 20px;
                animation-fill-mode: both;
                animation: slideIn 2s;
                @include mq(xl) {
                    font-size: 5rem;
                }
                @include mq(lg) {
                    font-size: 4rem;
                }
                @include mq(md) {
                    font-size: 3rem;
                    margin-bottom: 13px;
                }
                @include mq(sm) {
                    font-size: 4rem;
                }
                @include mq(xs) {
                    font-size: 3rem;
                    text-align: center;
                }
            }
            &-desc {
                font-size: 1.4rem;
                text-transform: capitalize;
                margin-bottom: 36px;
                line-height: 26px;
                color: #fff;
                opacity: 0.7;
                animation: slideIn 3s;
                @include mq(md) {
                    font-size: 1.4rem;
                    margin-bottom: 15px;
                    line-height: 24px;
                }
                @include mq(xs) {
                    margin-bottom: 15px;
                    line-height: 1.3;
                    text-align: center;
                }
                & br {
                    @include mq(lg) {
                        display: none;
                    }
                    @include mq(md) {
                        display: none;
                    }
                    @include mq(xs) {
                        display: none;
                    }
                }
            }
            &-list {
                & li {
                    list-style-type: none;
                    display: block;
                    &:not(:last-child) {
                        margin-bottom: 4px;
                    }
                    & i {
                        color: #2ba53f;
                        font-size: 14px;
                        margin-right: 10px;
                    }
                    & span {
                        font-size: 14px;
                        font-weight: 500;
                        color: #535559;
                    }
                }
            }
        }
    }
    
    .slider-shapes {
        @include mq(xs) {
            margin-top: 4rem;
         }
        & > *:not(.shape-1) {
            position: absolute;
        }
        & .shape-1 {
            animation: slideInRight 2s;
            & img {
                -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
                -moz-animation: hero-thumb-animation 2s linear infinite alternate;
                -o-animation: hero-thumb-animation 2s linear infinite alternate;
                animation: hero-thumb-animation 2s linear infinite alternate;
                max-width: inherit;
                 @include mq(xxl) {
                    max-width: 575px;
                }
                @include mq(xl) {
                    max-width: 500px;
                }
                @include mq(lg) {
                    max-width: 500px;
                }
                @include mq(md) {
                    max-width: 335px;
                }
                @include mq(sm) {
                    max-width: 100%;
                }
                @include mq(xs) {
                    max-width: 100%;
                }
            }
        }
        & .shape-2 {
            animation: fadeIn 3s;
            right: 450px;
            top: 140px;
            @include mq(xxl) {
                left: auto;
            }
            @include mq(xl) {
                right: 400px;
                top: 120px;
            }
            @include mq(lg) {
                right: 400px;
                top: 80px;
            }
            @include mq(md) {
                right: 210px;
                top: 70px;
            }
            @include mq(sm) {
                right: 328px;
                top: auto;
                bottom: -42px;
            }
            @include mq(xs) {
                left: 20px;
                top: auto;
                bottom: -42px;
            }
            & img {
                -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                @include  mq(xxl) {
                    max-width: 160px;
                }
                @include  mq(xl) {
                    max-width: 140px;
                }
                @include mq(lg) {
                    max-width: 132px;
                }
                @include mq(md) {
                    max-width: 108px;
                }
                @include mq(sm) {
                    max-width: 150px;
                }
                @include mq(xs) {
                    max-width: 120px;
                }
            }
        }
        & .shape-4 {
            animation: fadeIn 3s;
            position: absolute;
            top: -65px;
            right: 160px;
            @include mq(xl) {
                right: 150px;
            }
            @include mq(xxl) {
                position: absolute;
                top: -65px;
                right: 160px;
            }
            @include mq(lg) {
                right: 160px;
                top: 10px;
            }
            @include mq(md) {
                right: 160px;
                top: 0px;
            }
            @include mq(sm) {
                right: 160px;
                bottom: 230px;
                top: auto;
            }
             @include mq(xs) {
                bottom: 230px;
                top: auto;
                right: 160px;
            }
            
            & img {
                -webkit-animation: hero-thumb-sm-animation 4s linear infinite alternate;
                -moz-animation: hero-thumb-sm-animation 4s linear infinite alternate;
                -o-animation: hero-thumb-sm-animation 4s linear infinite alternate;
                animation: hero-thumb-sm-animation 4s linear infinite alternate;
                @include mq(xl) {
                    max-width: 190px;
                }
                @include mq(lg) {
                    max-width: 160px;
                }
                
                @include mq(md) {
                    max-width: 125px;
                }
                @include mq(sm) {
                    max-width: 180px;
    
                }
                @include mq(xs) {
                    max-width: 160px;
                }
                
            }
        }
        & .shape-3 {
            animation: fadeIn 3s;
            top: 118px;
            right: -10px;
            @include mq(xxl) {
                right: 20px;
            }
            @include mq(xl) {
                right: 48px;
                top: 72px;
            }
            @include mq(lg) {
                top: 120px;
                right: 42px;
            }
            @include mq(md) {
                top: 110px;
                right: 40px;
            }
            @include mq(sm) {
                right: 24px;
                bottom: -42px;
                top: auto;
            }
            @include mq(xs) {
                right: 20px;
                bottom: -42px;
                top: auto;
            }
            
            & img {
                -webkit-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                -moz-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                -o-animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                animation: hero-thumb-sm-2-animation 4s linear infinite alternate;
                @include mq(xl) {
                    max-width: 185px;
                }
                @include mq(lg) {
                    max-width: 175px;
                }
                @include mq(md) {
                    max-width: 125px;
                }
                @include mq(sm) {
                    max-width: 200px;
                }
                @include mq(xs) {
                    max-width: 170px;
                }
            }
        }
        & .shape-inner {
            & img {
                border-radius: 4px;
            }
        }
    }
    .has-shadow-1 {
        box-shadow: 0px 40px 40px 0px #00052724;
    }
    .has-shadow-2 {
        box-shadow: 0px 20px 70px 0px #00052733;
    }
    
    .has-shadow-3 {
        box-shadow: 0px 30px 70px 0px #0005271a;
    }
    .slider-shape-space {
        margin-top: -67px;
        @include mq(lg) {
            margin-top: 0;
        }
        @include mq(md) {
            margin-top: 0;
        }
    }
    .slider-shapes-2 {
        & .shape-default {
            position: absolute;
        }
        .shape-1 {
            top: 420px;
            left: 0;
            -webkit-animation: hero-thumb-animation 2s linear infinite alternate;
            -moz-animation: hero-thumb-animation 2s linear infinite alternate;
            -o-animation: hero-thumb-animation 2s linear infinite alternate;
            animation: hero-thumb-animation 2s linear infinite alternate;
            @include mq(xl) {
                left: -16px;
            }
            @include mq(lg) {
                left: -24px;
            }
            @include mq(md) {
                left: -15px;
            }
        }
        .shape-2 {
            top: 175px;
            left: 255px;
            -webkit-animation: hero-circle-2 4s linear 0s infinite alternate;
            -moz-animation: hero-circle-2 4s linear 0s infinite alternate;
            -o-animation: hero-circle-2 4s linear 0s infinite alternate;
            animation: hero-circle-2 4s linear 0s infinite alternate;
            @include mq(md) {
                top: 113px;
            }
        }
        .shape-3 {
            top: 220px;
            right: 100px;
            -webkit-animation: hero-triangle-1 6s linear infinite;
            -moz-animation: hero-triangle-1 6s linear infinite;
            -o-animation: hero-triangle-1 6s linear infinite;
            animation: hero-triangle-1 6s linear infinite;
        }
        .shape-4 {
            right: 120px;
            bottom: 140px;
            -webkit-animation: hero-circle-2 4s linear 0s infinite alternate;
            -moz-animation: hero-circle-2 4s linear 0s infinite alternate;
            -o-animation: hero-circle-2 4s linear 0s infinite alternate;
            animation: hero-circle-2 4s linear 0s infinite alternate;
            @include mq(md) {
                bottom: 70px;
            }
        }
    }
    .extra-slider-shape {
        @include mq(lg) {
            display: none;
        }
        & .shape-1 {
            position: absolute;
            bottom: 18px;
            left: 400px;
            -webkit-animation: services-triangle 7s linear infinite alternate;
            -moz-animation: services-triangle 7s linear infinite alternate;
            -o-animation: services-triangle 7s linear infinite alternate;
            animation: services-triangle 7s linear infinite alternate;
        }
        & .shape-2 {
            bottom: -297px;
            right: 370px;
            position: absolute;
            @include mq(md) {
                display: none;
            }
        }
    }
    .pos-visible {
        position: relative;
        z-index: 99;
    }
    
    
    .hero-gradient-2 {
      background: -moz-linear-gradient(
        180deg,
        rgba(0, 79, 255, 1) 0%,
        rgba(0, 161, 255, 1) 100%
      );
      background: -webkit-linear-gradient(
        180deg,
        rgba(0, 79, 255, 1) 0%,
        rgba(0, 161, 255, 1) 100%
      );
      background: linear-gradient(
        180deg,
        rgba(0, 79, 255, 1) 0%,
        rgba(0, 161, 255, 1) 100%
      );
    }
    
    
    .cat-slider-height-3 {
      min-height: 950px;
      @include mq(xs) {
        min-height: 770px;
      }
      @include mq(sm) {
        min-height: 950px;
      }
      @include mq(xl) {
        min-height: 880px;
      }
      @include mq(md) {
        padding-top: 140px;
      }
      @include mq(lg) {
        min-height: 678px;
      }
    }
    
    .hero-form-2 {
        position: relative;
        display: inline-block;
        animation: fadeIn 1.5s;
        // animation-delay: 8s;
        animation-timing-function: ease-in;
        @include mq(xs) {
           width: 100%;
           text-align: center;
          }
        & input {
            width: 400px;
            padding-left: 24px;
            height: 55px;
            display: block;
            padding-right: 140px;
            border-radius: 4px;
            border: 0;
            outline: none;
            border-radius: 28px;
            @include mq(xs) {
                width: 100%;
            }
        }
        & .email-label-2 {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 24px;
            @include transition(.3s);
            cursor: unset;
        }
        & button[type="submit"] {
            border-top-right-radius: 28px;
            border-bottom-right-radius: 28px;
        }
        .rectangle-btn-2 {
            border: 0;
            background-color: #ffa400;
            color: #fff;
            height: 55px;
            width: 140px;
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            @include mq(xs) {
                width: 123px;
            }
            &:hover{
                background-color: #ffb224;
            }
        }
        .email-label-2 {
            font-size: 14px;
            color: #9b989f;
        }
    }

  
}
.bottom-0{
    bottom: 0;
} 
@keyframes slideIn {
    from {
        margin-left: -100%;
        width: 300%;
      }
    
      to {
        margin-left: 0%;
        width: 100%;
      }
  }
  
@keyframes slideInRight {
    from {
        margin-left: 100%;
        width: 300%;
      }
    
      to {
        margin-left: 0%;
        width: 100%;
      }
  }

  @keyframes fadeIn {
    0% { opacity: 0; margin-left: -100%; }
    100% { opacity: 1; }
  }