.cat {
  &-footer {
    margin-top: 5rem;
    padding-top: 3rem;
    color: #ffffff;
    background: -moz-linear-gradient(180deg, rgb(0, 64, 205), rgb(0, 45, 101));
    background: -webkit-linear-gradient(
      180deg,
      rgb(0, 64, 205),
      rgb(0, 45, 101)
    );
    background: linear-gradient(180deg, rgb(0, 64, 205), rgb(0, 45, 101));
    &-widget-social {
        margin-top: 2rem;
      .title {
        font-family: $roboto;
        font-weight: 700;
        margin-bottom: 0;
        font-size: 15px;
        margin-bottom: 15px;
        color: var(--clr-common-white);
      }
      a {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        display: inline-block;
        text-align: center;
        line-height: 38px;
        margin-right: 5px;
        color: #deebff;
        font-size: 2rem;
        &:hover {
          background-color: var(--clr-theme-3);
          color: var(--clr-common-white);
          border-color: transparent;
        }
      }
    }

    &-widget {
        &-contact{
            color: #deebff;
            margin-top: 2rem;
        }
      &-title {
        font-weight: 700;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        width: fit-content;
        &::after {
          content: " ";
          border-bottom: 3px solid #fff;
          padding-top: 6px;
          width: 95%;
          display: flex;
        }
      }

      ul {
        padding: 0px;
        & li {
          list-style: none;
          margin-bottom: 10px;
          & a {
            display: block;
            font-family: "Roboto";
            font-weight: 400;
            text-decoration: none;
            font-size: 16px;
            color: var(--clr-body-gray-4);
            &:hover {
              color: var(--clr-theme-3);
            }
          }
        }
      }
      & p {
        font-family: $roboto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        opacity: 0.8;
      
      }

      &-contact {
        & a {
          display: block;
          text-decoration: none;
          font-weight: 400;
          font-size: 15px;
          color: #ffffff;
          margin-bottom: 12px;
          & svg {
            margin-right: 7px;
          }
        }
      }
    }
  }
  &-slider-content-title {
    font-size: 2rem;
    font-weight: 600;
    span {
      font-size: 2.3rem;
    }
  }
  &-logo {
    max-width: 125px;
  }
  &-copyright-border-top {
    margin-top: 1rem;
    // border-top: 1px solid #fff;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    @include mq(xs) {
        
      text-align: center;
      
     }
     .text-end{
      text-align: center;
      @include mq(xs) {
        
        text-align: center !important;
        
       }
    }
  }
  &-newspaper-form {
    input {
      height: 50px;
      margin-bottom: 1rem;
    }
    button {
      width: 120px;
      background-color: #f15e5e;
      color: #ffffff;
      border: none;
      &:hover {
        background-color: #ff3c3c;
        color: #fff;
      }
    }
  }
}
